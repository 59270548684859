const scenarios = [
    {
        title: "GCAM_SSP2",
    },
    {
        title: "GCAM_SSP3",
    },
    {
        title: "GCAM_SSP5",
    }
];

export default scenarios;